
import { OrganizationPlan } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { getModule } from 'vuex-module-decorators'

import Appbar from '@/components/appbar/index.vue'
import ScreenWarningInterstitial from '@/components/screen-warning-interstitial.vue'
import { findLast } from '@/helpers/find-last'
import * as ObjectPool from '@/helpers/object-pool'
import { InterstitialModule, InterstitialType } from '@/modules/interstitial/store'
import { LandscapeModule } from '@/modules/landscape/store'
import OrganizationUpgradeDialog from '@/modules/organization/components/upgrade-dialog.vue'
import * as organizationAnalytics from '@/modules/organization/helpers/analytics'
import { UpgradeTrigger } from '@/modules/organization/helpers/upgrade-triggers'
import { OrganizationModule } from '@/modules/organization/store'
import { RouteModule } from '@/modules/route/store'
import { UserModule } from '@/modules/user/store'

const USER_LAST_ACTIVE_INTERVAL = 5 * 60 * 1000 // 5 minutes

@Component({
  components: {
    Appbar,
    OrganizationUpgradeDialog,
    ScreenWarningInterstitial
  },
  name: 'App'
})
export default class extends Vue {
  interstitialModule = getModule(InterstitialModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  routeModule = getModule(RouteModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  @Ref() readonly contentRef!: HTMLElement

  createTimestamp = Date.now()
  refreshingApp = false
  reloadingPage = false
  updateFound = false
  updateExists = false
  registration: any

  userLastActiveTimer: number | undefined
  pruneObjectPoolInterval: number | undefined

  get loaded () {
    return this.$routeName && this.userModule.loaded
  }

  get background () {
    const background = findLast(this.$route.matched, o => o.meta.background)?.meta.background
    return (this.loaded && background) || '#1E1F20'
  }

  get backgroundColor () {
    switch (this.background) {
      case 'lights': return undefined
      default: return this.background
    }
  }

  get hasAppbar () {
    const hasAppbar = findLast(this.$route.matched, o => typeof o.meta.hasAppbar === 'boolean')?.meta.hasAppbar
    return typeof hasAppbar === 'boolean' ? hasAppbar : false
  }

  get userId () {
    return this.userModule.user?.id
  }

  get disableScreenWarning () {
    return this.$route.matched.some(o => o.meta.disableScreenWarning)
  }

  get showConnectionIssueInterstitial (): boolean {
    return this.interstitialModule.presentedType === InterstitialType.connectionIssue
  }

  get connectionIssueErrorMessage (): string | null {
    return this.interstitialModule.message
  }

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get isolatedEnvironmentBannerUrl (): string | undefined {
    switch (this.currentOrganizationId) {
      case 'uTZegFWUQTWl4ZVjXYKV':
        return 'https://mercadona.icepanel.cloud'
      default:
        return undefined
    }
  }

  @Watch('loaded')
  onLoadedChanged (loaded: boolean) {
    if (loaded) {
      this.checkUpgradeOrganizationCallback()
    }
  }

  @Watch('$route')
  onRouteChanged (route: Route) {
    this.routeModule.setName(route.name)
    this.routeModule.setParams(route.params)
    this.routeModule.setQuery(this.$normalizeQuery(route.query))

    setTimeout(() => {
      this.contentRef.scrollTo(0, 0)
    }, 150)
  }

  @Watch('userId')
  onUserChanged (userId: string | undefined) {
    clearInterval(this.userLastActiveTimer)

    if (userId) {
      this.updateUserLastActive()
    }
  }

  created () {
    document.addEventListener('swUpdated', event => {
      this.updateExists = true
      this.registration = (event as CustomEvent).detail

      if (Date.now() - this.createTimestamp < 20 * 1000) {
        this.refreshingApp = true

        setTimeout(() => {
          this.refreshApp()
        }, 3 * 1000)
      }
    })

    navigator.serviceWorker?.addEventListener('controllerchange', () => {
      if (this.reloadingPage) {
        return
      }
      this.reloadingPage = true

      window.location.reload()
    })
  }

  mounted () {
    this.routeModule.setName(this.$route.name)
    this.routeModule.setParams(this.$route.params)
    this.routeModule.setQuery(this.$normalizeQuery(this.$route.query))

    if (this.userId) {
      this.updateUserLastActive()
      this.userLastActiveTimer = window.setInterval(() => this.updateUserLastActive(), USER_LAST_ACTIVE_INTERVAL)
    }

    setTimeout(() => {
      document.dispatchEvent(new Event('render-trigger'))
    }, 500)

    /**
     * Stagger deletion of objects and textures from memory, never delete objects and textures at once
    */
    this.pruneObjectPoolInterval = window.setInterval(() => ObjectPool.pruneObjects(), 500)
  }

  destroyed () {
    clearInterval(this.userLastActiveTimer)
    clearInterval(this.pruneObjectPoolInterval)
  }

  checkUpgradeOrganizationCallback () {
    const upgradeOrgId = typeof this.$route.query.upgrade === 'string' ? this.$route.query.upgrade : undefined
    const upgradeResult = typeof this.$route.query.upgrade_result === 'string' ? this.$route.query.upgrade_result : undefined
    const upgradeTrigger = typeof this.$route.query.upgrade_trigger === 'string' ? this.$route.query.upgrade_trigger as UpgradeTrigger : undefined
    const upgradePlan = typeof this.$route.query.upgrade_plan === 'string' ? this.$route.query.upgrade_plan as OrganizationPlan : undefined
    const upgradeTrial = typeof this.$route.query.upgrade_trial === 'string' ? !!this.$route.query.upgrade_trial : undefined

    const billingOrgId = typeof this.$route.query.billing_organization === 'string' ? this.$route.query.billing_organization : undefined

    if (typeof upgradeOrgId === 'string' && upgradeResult === 'success') {
      setTimeout(() => {
        this.organizationModule.organizationFind(upgradeOrgId)
      }, 3000)
      setTimeout(() => {
        this.organizationModule.organizationFind(upgradeOrgId)
      }, 6000)
    } else if (billingOrgId) {
      setTimeout(() => {
        this.organizationModule.organizationFind(billingOrgId)
      }, 3000)
      setTimeout(() => {
        this.organizationModule.organizationFind(billingOrgId)
      }, 6000)
    }

    if (upgradeOrgId && upgradePlan && upgradeTrigger) {
      if (upgradeResult === 'success') {
        organizationAnalytics.organizationUpgradeSuccess.track(this, {
          organizationId: [upgradeOrgId],
          organizationUpgradePlan: upgradePlan,
          organizationUpgradeTrial: !!upgradeTrial,
          organizationUpgradeTrigger: upgradeTrigger
        })
      } else if (upgradeResult === 'cancel') {
        organizationAnalytics.organizationUpgradeCancel.track(this, {
          organizationId: [upgradeOrgId],
          organizationUpgradePlan: upgradePlan,
          organizationUpgradeTrial: !!upgradeTrial,
          organizationUpgradeTrigger: upgradeTrigger
        })
      }
    }

    this.$replaceQuery({
      billing_organization: undefined,
      upgrade: undefined,
      upgrade_plan: undefined,
      upgrade_result: undefined,
      upgrade_trial: undefined,
      upgrade_trigger: undefined
    })
  }

  refreshApp () {
    if (!this.updateExists) {
      return
    }
    this.refreshingApp = true

    // make sure we only send a 'skip waiting' message if the SW is waiting
    if (!this.registration || !this.registration.waiting) {
      return
    }

    // send message to SW to skip the waiting and activate the new SW
    this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
  }

  updateUserLastActive () {
    this.userModule.userUpdate({})
  }
}
